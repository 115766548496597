import type { ParcelInformation } from "@prisma/client";
import { AgroEcologicalInfrastructureType } from "@prisma/client";

import { getParcelLatestInformation } from "../parcelGetter";
import type { PartialDeep } from "../types";
import { AgroEcologicalInfrastructureCategory } from "./types";

const getAgroEcologicalInfrastructureCategory = (parcel: {
  informations?: PartialDeep<ParcelInformation>[];
}) => {
  const agroEcologicalInfrastructureType = getParcelLatestInformation(
    parcel,
    "information.agroEcologicalInfrastructureType"
  );

  if (
    !agroEcologicalInfrastructureType ||
    agroEcologicalInfrastructureType.length === 0 ||
    agroEcologicalInfrastructureType.includes(AgroEcologicalInfrastructureType.none)
  ) {
    return [AgroEcologicalInfrastructureCategory.None];
  }

  const categories = [];
  if (
    agroEcologicalInfrastructureType.some(
      (type) =>
        type === AgroEcologicalInfrastructureType.trees_within_plot ||
        type === AgroEcologicalInfrastructureType.trees_between_plots ||
        type === AgroEcologicalInfrastructureType.hedge
    )
  ) {
    categories.push(AgroEcologicalInfrastructureCategory.Trees);
  }

  if (
    agroEcologicalInfrastructureType.some(
      (type) =>
        type === AgroEcologicalInfrastructureType.melliferous_species ||
        type === AgroEcologicalInfrastructureType.grass_strips ||
        type === AgroEcologicalInfrastructureType.fallow_land ||
        type === AgroEcologicalInfrastructureType.permanent_grassland
    )
  ) {
    categories.push(AgroEcologicalInfrastructureCategory.Grass);
  }

  if (agroEcologicalInfrastructureType.includes(AgroEcologicalInfrastructureType.stone_wall)) {
    categories.push(AgroEcologicalInfrastructureCategory.Rock);
  }

  return categories;
};

export default getAgroEcologicalInfrastructureCategory;

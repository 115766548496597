import type {
  Exploitation,
  Farmer,
  HsuInformation,
  Indicators,
  LandCover,
  MeasuresCompleted,
  OrgCampaign,
  Parcel,
  ParcelAdditionalValue,
  ParcelCulture,
  ParcelIndicators,
  ParcelInformation,
  Prisma,
  SamplingPoint,
} from "@prisma/client";

import { sortedImpactIndices } from "~/lib/genu";
import { filterHead, filterTail } from "~/lib/helpers";

export type RatedExploitation = Exploitation & {
  parcels: RatedParcel[];
  farmers?: Array<{ farmer: Farmer }>;
  missions: Array<{
    campaign: OrgCampaign;
    hsus: Array<RatedHsu>;
  }>;
  ratings: ExploitationRatingWithCampaign[];
};

export type Rating = LegacyRating | NewRating;

type LegacyRating = {
  sampleId: number;
  hidden: boolean;
  metadata: Prisma.JsonValue | null;
  carbon: number | null;
  biodiversity: number | null;
  pollution: number | null;
  rating: number | null; /// @deprecated
  impactRating: number;
  biodiversityImpact: number | null;
  climateImpact: number | null;
  waterImpact: number | null;
  yieldImpact: number | null;
  version?: undefined;
};

type NewRating = {
  sampleId: number;
  hidden: boolean;
  metadata: Prisma.JsonValue | null;
  impactRating: number;
  version: "v1" | "v2";
};

type ExploitationRating = LegacyExploitationRating | NewExploitationRating;

type LegacyExploitationRating = {
  exploitationId: number;
  hidden: boolean;
  metadata: Prisma.JsonValue | null;
  carbon: number | null;
  biodiversity: number | null;
  pollution: number | null;
  rating: number | null;
  biodiversityImpact: number | null;
  climateImpact: number | null;
  waterImpact: number | null;
  yieldImpact: number | null;
  impactRating: number | null;
  campaignCode: string;
};

type NewExploitationRating = {
  exploitationId: number;
  hidden: boolean;
  metadata: Prisma.JsonValue | null;
  version: "v1" | "v2";
  impactRating: number | null;
  campaignCode: string;
};

export type ExploitationRatingWithCampaign = ExploitationRating & {
  campaign: OrgCampaign;
};

export interface RatedHsu {
  id: number;
  name: string | null;
  landCover: LandCover | null;
  code: string;
  area: number | null;
  location: Prisma.JsonValue | null;
  boundary?: Prisma.JsonValue | null;
  information?: HsuInformation | null;
  samplingPoints?: SamplingPoint[];
  samples?: {
    id: number;
    rating?: Rating | null;
    indicators?: Indicators | null;
    measuresCompleted: MeasuresCompleted | null;
  }[];
  samplingStartedAt: Date | null;
  samplingEndedAt: Date | null;
}

interface CalculatedPractice {
  id: number;
  seasonName: string;
  rotationScore: number | null;
  aggregatedAverageCoveredProportion: number | null;
  coveredSoilDays: number | null;
  coveredSoilDaysProportion: number | null;
  // TODO: GOOD TO SEE WHAT DIES WHEN REMOVED
  averageCoveredProportion: number | null;
  maxMissingSatelliteDataDays: number | null;
}

export interface ParcelDerivedMeasure {
  eCO2_stock_total?: number | null;
  eCO2_stock_per_ha?: number | null;
  water_stock_total?: number | null;
  water_stock_per_ha?: number | null;
  ref_eCO2_stock_total?: number | null;
  ref_eCO2_stock_per_ha?: number | null;
  ref_water_stock_total?: number | null;
  ref_water_stock_per_ha?: number | null;
  ref_min_eCO2_stock_total?: number | null;
  ref_min_eCO2_stock_per_ha?: number | null;
  ref_min_water_stock_total?: number | null;
  ref_min_water_stock_per_ha?: number | null;
  campaignCode?: string;
  campaign?: { name: string };
}

export type RatedParcel = Parcel & {
  ratings: ParcelRatingWithCampaign[];
  indicators: ParcelIndicatorsWithCampaign[];
  derivedMeasures?: ParcelDerivedMeasure[];
  informations: ParcelInformation[];
  cultures: ParcelCulture[];
  additionalValues: ParcelAdditionalValueWithOptionalCampaign[];
  calculatedPractices: CalculatedPractice[];
  hsusIntersections: Array<{
    hsu: {
      id: number;
      name: string | null;
      code?: string;
      mission: { campaign: OrgCampaign };
      samples: {
        rating?: Rating | null;
        measuresCompleted: MeasuresCompleted | null;
      }[];
      samplingStartedAt?: Date | null;
    };
  }>;
};

export type ParcelRating = LegacyParcelRating | NewParcelRating;

type LegacyParcelRating = {
  parcelId: number;
  hidden: boolean;
  metadata: Prisma.JsonValue | null;
  biodiversityImpact: number | null;
  climateImpact: number | null;
  waterImpact: number | null;
  yieldImpact: number | null;
  impactRating: number;
  carbon: number | null;
  biodiversity: number | null;
  pollution: number | null;
  confidence: number | null;
  campaignCode: string;
  version?: undefined;
};

type NewParcelRating = {
  parcelId: number;
  hidden: boolean;
  metadata: Prisma.JsonValue | null;
  impactRating: number;
  version: "v1" | "v2";
  campaignCode: string;
};

export type ParcelRatingWithCampaign = ParcelRating & {
  campaign: OrgCampaign;
};

export type ParcelIndicatorsWithCampaign = ParcelIndicators & {
  campaign: OrgCampaign;
};

export type ParcelAdditionalValueWithOptionalCampaign = ParcelAdditionalValue & {
  campaign?: Pick<OrgCampaign, "name"> | null;
};

export const isRatedParcel = (p: Parcel & Record<string, any>): p is RatedParcel => {
  if (!p.ratings || p.ratings.length === 0 || p.ratings[0].impactRating === null) {
    return false;
  }

  if (!p.indicators || p.indicators.length === 0) {
    return false;
  }

  return true;
};

export const isLegacyRating = (rating: Record<string, any>): rating is LegacyRating => {
  if (rating.versions === undefined) {
    return true;
  }

  return rating.impactRating !== undefined;
};

export const isLegacyParcelRating = (rating: Record<string, any>): rating is LegacyParcelRating => {
  if (rating.versions === undefined) {
    return true;
  }

  return rating.impactRating !== undefined;
};

export const isRatedExploitation = (
  e: Exploitation & Record<string, any>
): e is RatedExploitation => {
  return (
    e.ratings.length > 0 &&
    e.ratings[0].impactRating !== null &&
    sortedImpactIndices.every((index) => e.ratings[0][index] !== null) &&
    e.parcels.length > 0
  );
};

export const applyCampaignFilter = <T extends { campaign?: { name: string } | null }>(
  array: T[],
  campaignFilter?: string | null,
  options?: { allowEmptyCampaignValue: boolean }
) => {
  const defaultOptions = { allowEmptyCampaignValue: false };
  const { allowEmptyCampaignValue } = {
    ...defaultOptions,
    ...options,
  };

  if (!campaignFilter) {
    return array;
  } else {
    const filtered = filterHead(
      array,
      ({ campaign }) =>
        allowEmptyCampaignValue || (!!campaign?.name && campaign.name <= campaignFilter)
    );

    if (
      filtered.length > 0 &&
      (allowEmptyCampaignValue || filtered[0].campaign?.name === campaignFilter)
    ) {
      return filtered;
    } else {
      return [];
    }
  }
};

export const getRatedExploitations = <
  T extends Exploitation & {
    ratings: ExploitationRatingWithCampaign[];
    parcels: RatedParcel[];
  },
>(
  exploitations: T[] | null | undefined,
  campaignFilter?: string | null
) => {
  if (!exploitations) {
    return [];
  }

  return exploitations
    .map((exploitation) => ({
      ...exploitation,
      ratings: applyCampaignFilter(exploitation.ratings, campaignFilter),
    }))
    .filter(isRatedExploitation);
};

export const getRatedExploitation = (
  exploitation: RatedExploitation,
  campaignFilter?: string | null
) => ({
  ...exploitation,
  missions: campaignFilter
    ? filterTail(exploitation.missions, ({ campaign }) => campaign.name <= campaignFilter)
    : exploitation.missions,
  ratings: applyCampaignFilter(exploitation.ratings, campaignFilter),
  parcels: exploitation.parcels.map((parcel) => getRatedParcel(parcel, campaignFilter)),
});

export const getExploitationsRatedHsus = <
  T extends Exploitation & {
    parcels: RatedParcel[];
    missions: RatedExploitation["missions"];
  },
>(
  exploitations: T[] | null | undefined,
  campaignFilter?: string | null
): (RatedHsu & { parcels: RatedParcel[]; exploitation: Exploitation })[] => {
  if (!exploitations) {
    return [];
  }
  return exploitations.flatMap((exploitation) => {
    const mission = getLatestDisplayableMission(exploitation, {
      campaignFilter,
    });

    return (mission?.hsus || [])
      .map((h) => ({
        ...h,
        exploitation,
        parcels: exploitation.parcels.filter((p) =>
          p.hsusIntersections.some(({ hsu }) => hsu.id === h.id)
        ),
      }))
      .filter((hsu) => {
        const sample = hsu.samples?.[0];
        if (!sample || !sample.rating) {
          return false;
        }

        if (!isLegacyRating(sample.rating)) {
          return sample.rating.impactRating !== null;
        }

        return (
          sample.rating?.waterImpact !== null &&
          sample.rating?.biodiversityImpact !== null &&
          sample.rating?.yieldImpact !== null &&
          sample.rating?.climateImpact !== null &&
          sample.rating?.impactRating !== null
        );
      });
  });
};

export function getRatedParcel<T extends Partial<RatedParcel>>(
  parcel: T,
  campaignFilter?: string | null
) {
  return {
    ...parcel,
    ratings: parcel.ratings && applyCampaignFilter(parcel.ratings, campaignFilter),
    indicators: parcel.indicators && applyCampaignFilter(parcel.indicators, campaignFilter),
    additionalValues:
      parcel.additionalValues && applyCampaignFilter(parcel.additionalValues, campaignFilter),
    hsusIntersections:
      campaignFilter && parcel.hsusIntersections
        ? filterHead(
            parcel.hsusIntersections,
            (hsuIntersection) => hsuIntersection.hsu.mission.campaign.name <= campaignFilter
          )
        : parcel.hsusIntersections,
  };
}

export const getExploitationsRatedParcels = <
  T extends Exploitation & {
    parcels: RatedParcel[];
  },
>(
  exploitations: T[] | null | undefined,
  campaignFilter?: string | null
) => {
  if (!exploitations) {
    return [];
  }

  return exploitations.flatMap((exploitation) => {
    const parcels = exploitation.parcels ?? [];
    return parcels
      .map((p) => ({
        ...p,
        ratings: applyCampaignFilter(p.ratings, campaignFilter),
        indicators: applyCampaignFilter(p.indicators, campaignFilter),
        derivedMeasures: p.derivedMeasures
          ? applyCampaignFilter(p.derivedMeasures, campaignFilter, {
              allowEmptyCampaignValue: true,
            })
          : undefined,
        calculatedPractices: p.calculatedPractices,
        additionalValues: applyCampaignFilter(p.additionalValues, campaignFilter, {
          allowEmptyCampaignValue: true,
        }),
        exploitation: exploitation as T,
        measuresCompleted: p.hsusIntersections
          ? p.hsusIntersections
              .filter(
                (intersection) =>
                  !campaignFilter || intersection.hsu.mission.campaign.name === campaignFilter
              )
              .map(
                (intersection) =>
                  intersection.hsu.samples?.[0]?.measuresCompleted && {
                    ...intersection.hsu.samples?.[0]?.measuresCompleted,
                    campaignName: intersection.hsu.mission.campaign.name,
                  }
              )
              .filter((measuresCompleted) => measuresCompleted !== undefined)
          : undefined,
      }))
      .filter(isRatedParcel);
  });
};

export const getExploitationsParcels = (
  exploitations: RatedExploitation[] | null | undefined,
  campaignFilter?: string | null
) => {
  if (!exploitations) {
    return [];
  }

  return exploitations.flatMap((exploitation) => {
    const parcels = exploitation.parcels ?? [];
    return parcels.map((p) => ({
      ...p,
      ratings: applyCampaignFilter(p.ratings, campaignFilter),
      indicators: applyCampaignFilter(p.indicators, campaignFilter),
      additionalValues: applyCampaignFilter(p.additionalValues, campaignFilter, {
        allowEmptyCampaignValue: true,
      }),
      exploitation,
      measuresCompleted: p.hsusIntersections
        ? p.hsusIntersections
            .filter(
              (intersection) =>
                !campaignFilter || intersection.hsu.mission.campaign.name === campaignFilter
            )
            .map(
              (intersection) =>
                intersection.hsu.samples?.[0]?.measuresCompleted && {
                  ...intersection.hsu.samples?.[0]?.measuresCompleted,
                  campaignName: intersection.hsu.mission.campaign.name,
                }
            )
            .filter((measuresCompleted) => measuresCompleted !== undefined)
        : undefined,
    }));
  });
};

export function getLatestDisplayableMission<
  T extends {
    missions: Array<{
      campaignName?: string;
      campaign?: { name: string };
      hsus: Array<{
        samples?: Array<{ rating?: Partial<Rating> | null }>;
        samplingStartedAt?: Date | null;
        samplingEndedAt?: Date | null;
      }>;
    }>;
  },
>(exploitation: T, options?: { campaignFilter?: string | null }): T["missions"][0] | undefined {
  const scoredMissions = exploitation.missions.filter(
    ({ hsus }) =>
      hsus.length > 0 &&
      hsus.every(({ samples }) => samples?.length && samples?.every?.(({ rating }) => !!rating))
  );

  const filteredMissions = options?.campaignFilter
    ? filterTail(
        scoredMissions,
        ({ campaign = { name: "" }, campaignName = "" }) =>
          campaign?.name <= (options.campaignFilter as string) ||
          campaignName <= (options.campaignFilter as string)
      )
    : scoredMissions;

  // take the latest scored mission, if exists
  if (filteredMissions.length) {
    return filteredMissions[filteredMissions.length - 1];
  } else if (!options?.campaignFilter) {
    return exploitation.missions[exploitation.missions.length - 1];
  } else {
    return undefined;
  }
}

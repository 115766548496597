/* eslint-disable @typescript-eslint/naming-convention */
import { isNaN, last } from "lodash";
import colors from "tailwindcss/colors";

import type { AverageScores } from "~/lib/areaWeightedAverage";

// Ordered in priority from least to greatest
export enum RatingVersionTypes {
  none = "none",
  v1 = "v1",
  v2 = "v2",
}

type RatedParcel = {
  ratings: {
    version?: "v1" | "v2";
  }[];
};

// The priority version is the highest version that the user has access to
// combined with what is actually in the samples. If a user only has one possible version, we return that.
export const getDependenciesVersion = (
  parcels: RatedParcel[],
  userVersions: RatingVersionTypes[] | undefined
) => {
  if (!userVersions) {
    return RatingVersionTypes.none;
  }

  const orderedPossibleVersions = Object.values(RatingVersionTypes).filter((val) =>
    userVersions.includes(val)
  );

  if (parcels.length === 0) {
    return last(orderedPossibleVersions) ?? RatingVersionTypes.none;
  } else if (orderedPossibleVersions.length === 1) {
    return orderedPossibleVersions[0];
  }

  const allRatings = parcels.flatMap(({ ratings }) => ratings.map(({ version }) => version));

  if (allRatings.includes(RatingVersionTypes.v2) && userVersions.includes(RatingVersionTypes.v2)) {
    return RatingVersionTypes.v2;
  } else {
    return RatingVersionTypes.v1;
  }
};

export const toRatingsVersions = (
  user: {
    client?: { versions: RatingVersionTypes[] } | null;
    farmer?: { versions: RatingVersionTypes[] } | null;
  } | null,
  profile: "org" | "farmers"
) => {
  const versions = profile === "org" ? user?.client?.versions : user?.farmer?.versions;
  return versions ?? [RatingVersionTypes.none];
};

export const hasLegacyRatings = (
  user:
    | {
        client?: { versions: RatingVersionTypes[] } | null;
        farmer?: { versions: RatingVersionTypes[] } | null;
      }
    | null
    | undefined,
  role: string
) => {
  // Weird edge case. Remove any invalid states coming in.
  if (!user) {
    return true;
  }
  if (role === "farmers") {
    // If customer has Legacy, then that's all they should have
    if (user.farmer?.versions.includes(RatingVersionTypes.none)) {
      return true;
    } else {
      return false;
    }
  } else {
    // If customer has Legacy, then that's all they should have
    if (user.client?.versions.includes(RatingVersionTypes.none)) {
      return true;
    } else {
      return false;
    }
  }
};

export interface CampaignScore {
  campaignCode?: string;
  hidden?: boolean;
  value: number | null;
}

export interface NonNullCampaignScore {
  campaignCode?: string;
  hidden?: boolean;
  value: number;
}

export const HIGH_SCORE = 60;
export const LOW_SCORE = 40;

export const getRatingColor = (rating: number) => {
  const rounded = Math.round(rating);
  if (isNaN(rating)) {
    return colors.gray;
  } else if (rounded < LOW_SCORE) {
    return colors.red;
  } else if (rounded < HIGH_SCORE) {
    return colors.yellow;
  } else {
    return colors.lime;
  }
};

export enum ScoreLegacyClass {
  High = "A",
  Medium = "B",
  Low = "C",
  Undefined = "-",
}

export const getLegacyRatingLetter = (value?: number) => {
  if (value === undefined || Number.isNaN(value)) {
    return ScoreLegacyClass.Undefined;
  }

  const rounded = Math.round(value);

  if (rounded >= HIGH_SCORE) {
    return ScoreLegacyClass.High;
  } else if (rounded >= LOW_SCORE) {
    return ScoreLegacyClass.Medium;
  } else {
    return ScoreLegacyClass.Low;
  }
};

export const OPTIMAL_SCORE = 8;
export const GOOD_SCORE = 6;
export const FAIR_SCORE = 4;
export const DEGRADED_SCORE = 2;

export enum ScoreClass {
  Optimal = "Optimal",
  Good = "Good",
  Fair = "Fair",
  Degraded = "Degraded",
  Critical = "Critical",
  Undefined = "-",
}

export const getRating = (value?: number) => {
  if (value === undefined || Number.isNaN(value)) {
    return;
  }

  return Math.floor(Math.min(10, (10 * value) / 100));
};

export const getAveragedRating = (value?: number) => {
  if (value === undefined || Number.isNaN(value)) {
    return;
  }

  return Math.floor(Math.min(50, (50 * value) / 100)) / 5;
};

export const getScoreClass = (rating?: number) => {
  if (rating === undefined) {
    return ScoreClass.Undefined;
  }

  if (rating >= OPTIMAL_SCORE) {
    return ScoreClass.Optimal;
  } else if (rating >= GOOD_SCORE) {
    return ScoreClass.Good;
  } else if (rating >= FAIR_SCORE) {
    return ScoreClass.Fair;
  } else if (rating >= DEGRADED_SCORE) {
    return ScoreClass.Degraded;
  } else {
    return ScoreClass.Critical;
  }
};

export const getRatingValue = (
  scores?: AverageScores | CampaignScore[]
): number | null | undefined => (Array.isArray(scores) ? scores?.[0]?.value : scores?.value);

export const getRatingLatestCampaignCode = (
  scores?: AverageScores | CampaignScore[]
): string | undefined => {
  return Array.isArray(scores) ? scores?.[0]?.campaignCode : scores?.history[0].campaignCode;
};

export const campaignCodeToName = (campaignCode?: string): string | undefined => {
  const matches = campaignCode?.match(/20\d{2}.*$/);
  return matches?.[0];
};

export default getRatingColor;

import { useEffect, useState } from "react";

import type { INotification } from "~/hooks/core/useNotificationState";
import useNotificationState from "~/hooks/core/useNotificationState";

import Snackbar from "../ui/Snackbar";

const NotificationContainer = () => {
  const { notifications } = useNotificationState();

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </div>
    </div>
  );
};

const Notification = ({ notification }: { notification: INotification & { id: number } }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
    if (notification.timeout) {
      const t = setTimeout(() => setShow(false), notification.timeout);
      return () => clearTimeout(t);
    }
  }, [notification.timeout]);

  const removeNotification = useNotificationState((state) => state.removeNotification);

  useEffect(() => {
    if (show === false) {
      const t = setTimeout(() => removeNotification(notification.id), 500);

      return clearTimeout(t);
    }
  }, [notification.id, removeNotification, show]);

  return (
    <Snackbar open={show} setOpen={setShow} type={notification.type}>
      {notification.text}
    </Snackbar>
  );
};

export default NotificationContainer;

import NextHead from "next/head";
import { DefaultSeo } from "next-seo";

import config from "~/constants/seo.json";

const Head = () => {
  return (
    <>
      <DefaultSeo {...config} />
      <NextHead>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link key="site-manifest" href="/site.webmanifest" rel="manifest" />
        <link rel="icon" type="image/png" href="/images/icons/256x256.png" sizes="256x256" />
      </NextHead>
    </>
  );
};

export default Head;

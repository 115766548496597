import { useRouter } from "next/router";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";

import useAnalytics from "~/hooks/core/useAnalytics";
import { HJID, HJSV } from "~/lib/analytics";

const Analytics = () => {
  const router = useRouter();
  const { loadAnalytics, pageView, trackEvent } = useAnalytics();

  useEffect(() => {
    const handleRouteChange = () => {
      pageView();
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, pageView, trackEvent]);

  useEffect(() => {
    if (HJID && HJSV) {
      hotjar.initialize(+HJID, +HJSV);
    }
  }, []);

  useEffect(() => {
    loadAnalytics();
  }, [loadAnalytics]);

  return null;
};

export default Analytics;

import cn from "classnames";
import { useEffect } from "react";

import useAnimation from "~/hooks/useAnimation";
import useKonamiCode from "~/hooks/useKonamiCode";

const EasterEgg = () => {
  const { success, reset } = useKonamiCode();
  if (success) {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 w-screen h-screen pointer-events-none flex-center z-[9999]">
        <TractorAnimation reset={reset} />
      </div>
    );
  } else {
    return null;
  }
};

const TractorAnimation = ({ reset }: { reset: () => void }) => {
  const { ref, animation } = useAnimation({ path: "/animations/tractor.json" });

  useEffect(() => {
    const timeout = setTimeout(reset, 20000);
    return () => clearTimeout(timeout);
  }, [reset]);

  return (
    <div
      ref={ref}
      className={cn("w-full h-full transform transition-transform", {
        "-translate-x-full": !animation?.isLoaded,
        "translate-x-full ease-in duration-10000": animation?.isLoaded,
      })}
    />
  );
};

export default EasterEgg;
